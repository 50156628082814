import React from "react";
import "./Pricing.css";

const Pricing = () => {
  return (
    <div className="container-fluid py-5">
      <div className="text-center mx-auto ">
        <h2 className="d-inline-block text-danger ">Pricing</h2>
        <p className="display-6">We Provide Those Pricing</p>
      </div>

      <div className="container-pricing">
        <div className="  py-3 row">
          <div className="card card-pricing text-center  mx-auto  mb-4 col-lg-3 col-sm-3">
            <span className="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-danger text-white shadow-sm">
              Starter
            </span>
            <div className="bg-transparent card-header pt-4 border-0">
              <h1
                className="h1 font-weight-normal text-danger text-center mb-0"
                data-pricing-value="15"
              >
                $<span className="price">3</span>
                <span className="h6 text-muted ml-2">/ per month</span>
              </h1>
            </div>
            <div className="card-body pt-0">
              <ul className="list-unstyled mb-4">
                <li>Up to 5 users</li>
                <li>Basic support on Github</li>
                <li>Monthly updates</li>
                <li>Free cancelation</li>
              </ul>
              <button type="button" className="btn btn-outline-secondary mb-3">
                Order now
              </button>
            </div>
          </div>

          <div className="  card card-pricing  popular  mx-auto text-center  mb-4 col-lg-3 col-sm-3">
            <span className="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-danger text-white shadow-sm">
              Professional
            </span>
            <div className="bg-transparent card-header pt-4 border-0">
              <h1
                className="h1 font-weight-normal text-danger text-center mb-0"
                data-pricing-value="30"
              >
                $<span className="price">6</span>
                <span className="h6 text-muted ml-2">/ per month</span>
              </h1>
            </div>
            <div className="card-body pt-0">
              <ul className="list-unstyled mb-4">
                <li>Up to 5 users</li>
                <li>Basic support on Github</li>
                <li>Monthly updates</li>
                <li>Free cancelation</li>
              </ul>
              <button type="button" className="btn btn-outline-secondary mb-3">
                Order now
              </button>
            </div>
          </div>

          <div className="card card-pricing text-center  mx-auto  mb-4 col-lg-3 col-sm-3">
            <span className="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-danger text-white shadow-sm">
              Business
            </span>
            <div className="bg-transparent card-header pt-4 border-0">
              <h1
                className="h1 font-weight-normal text-danger text-center mb-0"
                data-pricing-value="45"
              >
                $<span className="price">9</span>
                <span className="h6 text-muted ml-2">/ per month</span>
              </h1>
            </div>
            <div className="card-body pt-0">
              <ul className="list-unstyled mb-4">
                <li>Up to 5 users</li>
                <li>Basic support on Github</li>
                <li>Monthly updates</li>
                <li>Free cancelation</li>
              </ul>
              <button type="button" className="btn btn-outline-secondary mb-3">
                Order now
              </button>
            </div>
          </div>

          <div className="card card-pricing text-center  mx-auto  mb-4 col-lg-3 col-sm-3">
            <span className="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-danger text-white shadow-sm">
              Enterprise
            </span>
            <div className="bg-transparent card-header pt-4 border-0">
              <h1
                className="h1 font-weight-normal text-danger text-center mb-0"
                data-pricing-value="60"
              >
                $<span className="price">12</span>
                <span className="h6 text-muted ml-2">/ per month</span>
              </h1>
            </div>
            <div className="card-body pt-0">
              <ul className="list-unstyled mb-4">
                <li>Up to 5 users</li>
                <li>Basic support on Github</li>
                <li>Monthly updates</li>
                <li>Free cancelation</li>
              </ul>
              <button type="button" className="btn btn-outline-secondary mb-3">
                Order now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
