import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes,Route } from 'react-router-dom';

import Home from './Components/Home/Home/Home';
import TopNav from './Components/Shared/TopNav/TopNav';
import Pricing from './Components/Pages/Pricing/Pricing';
import Footer from './Components/Shared/Footer/Footers/Footer';
import NotPage from './Components/Pages/NotPage/NotPage';
import Service from './Components/Pages/Service/Service';
import About from './Components/Pages/About/About';
import Contact from './Components/Pages/Contact/Contact';
import OmegaHMS from './Components/Pages/Products/OmegaHMS/OmegaHMS';
import OmegaDMS from './Components/Pages/Products/OmegaDMS/OmegaDMS';
import OmegaERP from './Components/Pages/Products/OmegaERP/OmegaERP';
import DoctorPad from './Components/Pages/Products/DoctorPad/DoctorPad';
import Navigation from './Components/Shared/Navigation/Navigation';

/** 
	* OmegaSoftBD App root .
	* @pupose		App main page information	
	* @filesource	src/App.js	
	* @package		omegasoft 
	* @subpackage	omegasoft.App.
	* @version      $Revision: 1
	* @author       $Author: Mohtasim Shariar  $
	* @lastmodified $Date: 2022-10-29 $	
*/ 


function App() {
  return (
   <div className="App">
    <TopNav></TopNav>

    <Navigation></Navigation>
  
     <Routes>
       <Route path="/" element={<Home />}></Route>
       <Route path="/home" element={<Home />}></Route>
       <Route path="/about" element={<About/>}></Route>
       <Route path="/service" element={<Service/>}></Route>
       <Route path="/omegaERP" element={<OmegaERP/>}></Route>
       <Route path="/omegaDMS" element={<OmegaDMS/>}></Route>
       <Route path="/omegaHMS" element={<OmegaHMS/>}></Route>
       <Route path="/DoctorPad" element={<DoctorPad/>}></Route>
       <Route path="/pricing" element={<Pricing />}></Route>
       <Route path="/contact" element={<Contact/>}></Route>
       <Route path="/*" element={<NotPage />}></Route>
     </Routes>
  
     <Footer></Footer>
    </div>
  );
};

export default App;

