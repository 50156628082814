import React from "react";
import "./subscribe.css";
import { Link } from "react-router-dom";

const subscribe = () => {
  return (
    <div>
      <section className="container-fluid newsletter">
        <div className="container-subscribe">
          <div className="row">
            <div className="col-sm-12">
              <div className="content">
                <h2>Are you Ready to have a Talk?</h2>
                <Link to="/contact" className="btn btn-large btn-danger">
                  <i className="icon-home icon-white"></i> Contact Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default subscribe;
