import React from "react";
import "./DoctorPad.css";
import omegaproduct1 from "../../../../assets/images/omegasoftwork2.jpg";

const DoctorPad = () => {
  return (
    <div className="container-fluid pt-5 pb-5">
    <div className="container-omega">
      
    
    <div className="text-center mx-auto  ">
      <h2 className=" d-inline-block text-danger  ">Omega DoctorPad</h2>
    </div>

    <div className="products-section">
      <div className="row">
        <div className="col-md-6 product-img">
        <img className="rounded-circle img-fluid" src={omegaproduct1} alt="" />
        </div>
        <div className="col-md-6">
          <h2 className="display-6">Doctors Pad System</h2>
          <p className="subheading">Description Of Our System</p>
          <p className="text_style text-muted">
            Doctors Pad is medical software for writing, maintaining and accessing prescriptions. This is Indias most trusted and fool proof prescription writing medical software which has also been trusted by many western countries. Medical prescription writing software for Bangladesh market that checks for drug compatibility and condition-related health risk factors. 
<br />Doctors Pad solution that helps doctors, patients, hospitals & other medical institutions manage prescriptions storage/transfer, medical certificates, reports and more.
          </p>
        </div>
      </div>
    </div>



{/* Details */}
<div className="pb-5 container-feature text-center mx-auto pt-5  col-lg-12  col-12  rounded-5">
        <p className="display-6">Key Features Of DoctorsPad:</p>
      
      <div className=" row">
        <div className="col-lg-6  col-12 col-sm-6 col-md-6  ">
          <div className="omega-background">
            <p className="count-text px-3 text-danger">Activities</p>
            <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
              <li className="mt-2">❖ Doctors Details</li>
              <li className="mt-2">❖ Medicine Information <br/>(5500+ medicine database)</li>
              <li className="mt-2">❖ Test Name Create</li> 
              <li className="mt-2">❖ Prescription Create</li> 
              <li className="mt-2">❖ Patients Create</li> 
              <li className="mt-2">❖ Diagnosis test invoice</li> 
            </ul>
          </div>
        </div>
        <div className="col-lg-6  col-12 col-sm-6 col-md-6 ">
          <div className="omega-background">
          <p className="count-text text-start px-3  text-danger">Reports</p>
              <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
              <li className="mt-2">❖ Prescription View</li> 
              <li className="mt-2">❖ Prescription History</li> 
              <li className="mt-2">❖ Medicine List</li> 
              <li className="mt-2">❖ Patient wise report</li> 
            </ul>
          </div>
        </div>
      </div>
      </div>
      {/* 2nd part */}

      <div className="pt-5 container-feature text-center mx-auto pt-5  col-lg-12  col-12  rounded-5" >
        <p className="display-6">Key Features of Accounting:</p>
      
      <div className=" row">
        <div className="col-lg-6  col-12 col-sm-6 col-md-6  ">
          <div className="omega-background">
          <p className="count-text text-start px-3  text-danger">Activities</p>
              <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
              <li className="mt-2">❖ Chart of Accounts</li>
              <li className="mt-2">❖ Opening Balance</li>
              <li className="mt-2">❖ Vouchers (Receipt, Payment, Journal, Contra, Balance Transfer)</li>
              <li className="mt-2">❖ Party Management</li>
              <li className="mt-2">❖ Employee/Staff Management</li>
              <li className="mt-2">❖ Configuration</li>
            
            </ul>
          </div>
        </div>
        <div className="col-lg-6  col-12 col-sm-6 col-md-6 ">
          <div className="omega-background">
          <p className="count-text text-start px-3  text-danger">Reports</p>
              <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
              <li className="mt-2">❖ Chart of Accounts</li>
              <li className="mt-2">❖ Ledger Report</li>
              <li className="mt-2">❖ Daily Transaction</li>
              <li className="mt-2">❖ Cash Book</li>
              <li className="mt-2">❖ Bank Book</li>
              <li className="mt-2">❖ Cash & Bank Book</li>
              <li className="mt-2">❖ Trial Balance Report</li>
              <li className="mt-2">❖ Receipt Payment Statement</li>
              <li className="mt-2">❖ Income Statement or Profit-Loss Statement</li>
              <li className="mt-2">❖ Balance Sheet Report</li>
              <li className="mt-2">❖ Party Ledger Report</li>
              <li className="mt-2">❖ Accounts payable receivable </li>
            </ul>
          </div>
        </div>

      </div>
      </div>



      </div>
  </div>
  );
};

export default DoctorPad;
