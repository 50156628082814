import React from 'react';
import CountUp from "react-countup";

const Counteritems = ({ number, title }) => {
    return (
        <div className="container-fluid ">
            <div className="number">
      <CountUp duration={5} className="counter" end={number} />
      <span>{title}</span>
    </div>
        </div>
    );
};

export default Counteritems;