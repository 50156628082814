import React from "react";
import "./NotPage.css";
import { Link } from "react-router-dom";

const NotPage = () => {
  return (
    <div className="Container-fluid mt-5 mb-5">
      <div className=" center">
        <h1>
          Page Not Found{" "}
          <small>
            <font face="Tahoma" color="red">
              Error 404
            </font>
          </small>
        </h1>
        <br />
        <p>The page you requested could not be found,</p>{" "}
        <p>
          {" "}
          either contact your webmaster or try again. Use your browsers{" "}
          <b>Back</b> button to navigate to the page you have prevously come
          from
        </p>
        <p>
          <b>Or you could just press this neat little button:</b>
        </p>
        <Link to="/" className="btn btn-large btn-danger">
          <i className="icon-home icon-white"></i> Take Me Home
        </Link>
      </div>
    </div>
  );
};

export default NotPage;
