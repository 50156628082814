import React from "react";
import "./OmegaERP.css";
import omegaproduct1 from "../../../../assets/images/omegasoftwork2.jpg";

const OmegaERP = () => {
  return (
    <div className="container-fluid pt-5 pb-5">
      <div className="container-omega">
        
  
      
      <div className="text-center mx-auto  ">
        <h2 className=" d-inline-block text-danger  ">Omega Enterprise resource planning</h2>
      </div>

      <div className="products-section">
        <div className="row">
          <div className="col-md-6 product-img">
           
                    <img className="rounded-circle img-fluid" src={omegaproduct1} alt="" />
          </div>
          <div className="col-md-6">
            <h2 className="display-6">OmegaERP System</h2>
            <p className="subheading">Description Of Our System</p>
            <p className="text_style text-muted">
            OmegaERP is one of the leading Online ERP solutions for Industrial Manufacturing & Production oriented companies. It also use in small business. It provides a complete solution to assist you in making your growing business more productive, competitive and responsive to your customer needs and production requirements. The system works with combined modules of-
            </p>
          </div>
        </div>
      </div>


 
{/* Details */}
<div className="pb-5 container-feature text-center mx-auto pt-5  col-lg-12  col-12  rounded-5">
          <p className="display-6 ">Key Features Of Inventory:</p>
        
        <div className=" row">
          <div className="col-lg-6  col-12 col-sm-6 col-md-6  ">
            <div className="omega-background">
              <p className="count-text text-start px-3  text-danger">Activities</p>
              <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
                <li className="mt-2">❖ Product Create</li>
                <li className="mt-2">❖ Go down Create</li>
                <li className="mt-2">❖ Customer/Supplier Management</li>
                <li className="mt-2">❖ Salesman Create</li>
                <li className="mt-2">❖ Opening Balance of Stocks</li>
                <li className="mt-2">❖ Sales Invoice/Memo Create</li>
                <li className="mt-2">❖ Purchase Invoice/Memo Create</li>
                <li className="mt-2">❖ Sales/Purchase Return</li>
                <li className="mt-2">❖ Item Transfer</li>
                <li className="mt-2">❖ Representative Management</li>
                <li className="mt-2">❖ BARCODE Enabled System</li>
                <li className="mt-2">❖ Real time accounting </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6  col-12 col-sm-6 col-md-6 ">
            <div className="omega-background">
              <p className="text-start px-3 count-text text-danger">Reports</p>
              <ul className="text-start px-5  justify-content-start roll-list list-unstyled   text-muted">
                <li className="mt-2">❖ Category, Brand, Item wise Stock Report.</li>
                <li className="mt-2">❖ Category, Brand, Item wise Stock Report (with price).</li>
                <li className="mt-2">❖ Stock Movement Register.</li>
                <li className="mt-2">❖ Day Book Report</li>
                <li className="mt-2">❖ Sales Register</li>
                <li className="mt-2">❖ Party wise Sales Report</li>
                <li className="mt-2">❖ Purchase Register</li>
                <li className="mt-2">❖ Product wise gross profit.</li>
                <li className="mt-2">❖ Item Warning Report</li>
                <li className="mt-2">❖ Item Transfer Report</li>
                <li className="mt-2">❖ Representative Sales Report</li>
           
              </ul>
            </div>
          </div>
        </div>
        </div>
        {/* 2nd part */}

        <div className="pt-5 container-feature text-center mx-auto pt-5  col-lg-12  col-12  rounded-5" >
          <p className="display-6">Key Features of Accounting:</p>
        
        <div className=" row">
          <div className="col-lg-6  col-12 col-sm-6 col-md-6  ">
            <div className="omega-background">
            <p className="text-start px-3 count-text text-danger">Activities</p>
              <ul className="text-start px-5  justify-content-start roll-list list-unstyled   text-muted">
                <li className="mt-2">❖ Chart of Accounts</li>
                <li className="mt-2">❖ Opening Balance</li>
                <li className="mt-2">❖ Vouchers (Receipt, Payment, Journal, Contra, Balance Transfer)</li>
                <li className="mt-2">❖ Party Management</li>
                <li className="mt-2">❖ Employee/Staff Management</li>
                <li className="mt-2">❖ Configuration</li>
             
              </ul>
            </div>
          </div>
          <div className="col-lg-6  col-12 col-sm-6 col-md-6 r">
            <div className="omega-background">
            <p className="text-start px-3 count-text text-danger">Reports</p>
              <ul className="text-start px-5  justify-content-start roll-list list-unstyled   text-muted">
                <li className="mt-2">❖ Chart of Accounts</li>
                <li className="mt-2">❖ Ledger Report</li>
                <li className="mt-2">❖ Daily Transaction</li>
                <li className="mt-2">❖ Cash Book</li>
                <li className="mt-2">❖ Bank Book</li>
                <li className="mt-2">❖ Cash & Bank Book</li>
                <li className="mt-2">❖ Trial Balance Report</li>
                <li className="mt-2">❖ Receipt Payment Statement</li>
                <li className="mt-2">❖ Income Statement or Profit-Loss Statement</li>
                <li className="mt-2">❖ Balance Sheet Report</li>
                <li className="mt-2">❖ Party Ledger Report</li>
                <li className="mt-2">❖ Accounts payable receivable </li>
              </ul>
            </div>
          </div>

        </div>
        </div>


        </div>
    </div>
  );
};

export default OmegaERP;
