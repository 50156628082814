import React from "react";
import "./Values.css";
import value from "../../../assets/images/blog_img2.png";

const Values = () => {
  return (
    <div className="container-fluid pt-5 pb-5">
      <div className="text-center mx-auto  ">
        <h2 className="d-inline-block text-danger text-uppercase border-bottom ">
          Our Values
        </h2>
      </div>

      <div className="container-value">
        <div className="row">
          <div className="col-lg-6 col-md-6  value_img rounded-5 ">
            <img className="icon" src={value} alt="" />
          </div>

          <div className="col-lg-6 col-md-6  ">
            <p className="display-6 ">
              What you get if you start your business with us
            </p>

            <ul className="text_style text-start justify-content-start roll-list list-unstyled text-muted">
              <li className="mt-2 text_style">
                * Honesty and Integrity towards our work and our clients.
                Steadfast adherence to what we say and what we do.
              </li>
              <li className="mt-2 text_style">
                * We believe in long-term relations with our clients. We want
                them to become part of our Team In OmegaSoft family.
              </li>
              <li className="mt-2 text_style">
                {" "}
                * Discipline It is one of the important factors helping us
                achieve our desired goals.
              </li>
              <li className="mt-2 text_style">
                * Commitment / Focus towards work- It always helps us in
                improving our services to our clients and our team.
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Values;
