import React from "react";
import "./Counter.css";
import Counteritems from "./Counteritems";

const Counter = () => {
  return (
    <div classNameName="container-fluid pt-5 pb-5">
      <div className="text-center mx-auto  ">
        <h2 className="d-inline-block text-danger  ">OmegaSoft Counter</h2>
        <p className="display-6">counter to count up to a target number</p>
      </div>

      <div className="container-counter row">
        <div className="col-lg-3  col-6 col-sm-3 col-md-3  ">
          <div className="counter">
            <i className="fa fa-code fa-2x"></i>
            <Counteritems number={4} />
            <p className="count-text ">Our Products</p>
          </div>
        </div>

        <div className="col-lg-3  col-6 col-sm-3 col-md-3 ">
          <div className="counter">
            <i className="fa fa-lightbulb-o fa-2x"></i>
            <Counteritems number={16} />
            <p className="count-text ">Industry Cover</p>
          </div>
        </div>

        <div className="col-lg-3  col-6 col-sm-3 col-md-3 ">
          <div className="counter">
            <i className="fa fa-solid fa-users fa-2x"></i>
            <Counteritems number={8} />
            <p className="count-text ">Our Staff</p>
          </div>
        </div>

        <div className="col-lg-3  col-6 col-sm-3 col-md-3 ">
          <div className="counter">
            <i className="fa  fa-coffee fa-2x "></i>
            <i class="fa-thin fa-people-group"></i>
            <Counteritems number={52} />
            <p className="count-text ">Happy Clients</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
