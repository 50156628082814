import React from "react";
import "./OmegaDMS.css";
import omegaproduct1 from "../../../../assets/images/omegasoftwork2.jpg";

const OmegaDMS = () => {
  return (
    <div className="container-fluid pt-5 pb-5">
    <div className="container-omega">
      
    
    <div className="text-center mx-auto  ">
      <h2 className=" d-inline-block text-danger  ">Omega Distribution Management System</h2>
    </div>

    <div className="products-section">
      <div className="row">
        <div className="col-md-6 product-img">
        <img className="rounded-circle img-fluid" src={omegaproduct1} alt="" />
        </div>
        <div className="col-md-6">
          <h2 className="display-6"> DMS System</h2>
          <p className="subheading">Description Of Our System</p>
          <p className="text_style text-muted">
          OmegaDMS has a suite of selective modules specifically tailored to the requirements of dealership/distribution system. 
<br />Distributor Management Systems (DMS) are now a fundamental component in any consumer goods company’s route-to-market. They help manage the supply chain between Manufacturers and a myriad of Distributors by aligning retail activities, trade promotions, inventory, invoicing, claims, competitive insights and much more.
<br />A modern DMS streamlines all distribution workflows and activities, improves supply chain efficiency, eliminates stock outs and overstocking and allows companies to access real time data from distributors. The right solution can bolster sales and distribution activities even in a fragmented marketplace, but let’s examine exactly why it is that they’re so important.
          </p>
        </div>
      </div>
    </div>



{/* Details */}
<div className="pb-5 container-feature text-center mx-auto pt-5  col-lg-12  col-12  rounded-5">
        <p className="display-6">Key Features Of Distribution:</p>
      
      <div className=" row">
        <div className="col-lg-6  col-12 col-sm-6 col-md-6  ">
          <div className="omega-background">
          <p className="count-text text-start px-3  text-danger">Activities</p>
              <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
              <li className="mt-2">❖ Company Create</li>
              <li className="mt-2">❖ Brand Create</li>
              <li className="mt-2">❖ Secondary Claim</li>
              <li className="mt-2">❖ Lifting/Purchase </li>
              <li className="mt-2">❖ Order Collect</li>
              <li className="mt-2">❖ Stock out Process</li>
              <li className="mt-2">❖ Delivery Process</li>
           
            </ul>
          </div>
        </div>
        <div className="col-lg-6  col-12 col-sm-6 col-md-6 ">
          <div className="omega-background">
          <p className="count-text text-start px-3  text-danger">Reports</p>
              <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
              <li className="mt-2">❖ Stock out details</li>
              <li className="mt-2">❖ Company wise Balance</li>
              <li className="mt-2">❖ T.L.O Report</li>
              <li className="mt-2">❖ Vouchers Report</li>
              <li className="mt-2">❖ Secondary Claim Report </li>
            
            </ul>
          </div>
        </div>
      </div>
      </div>
      {/* 2nd part */}

      <div className="pt-5 container-feature text-center mx-auto pt-5  col-lg-12  col-12  rounded-5" >
        <p className="display-6">Key Features of Inventory:</p>
      
      <div className=" row">
        <div className="col-lg-6  col-12 col-sm-6 col-md-6  ">
          <div className="omega-background">
          <p className="count-text text-start px-3  text-danger">Activities</p>
              <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
              <li className="mt-2">❖ Product Create</li>
              <li className="mt-2">❖ Go Down Create</li>
              <li className="mt-2">❖ Customer/Supplier Management</li>
              <li className="mt-2">❖ Salesman Create</li>
              <li className="mt-2">❖ Opening Balance of Stocks</li>
              <li className="mt-2">❖ Sales Invoice/Memo Create</li>
              <li className="mt-2">❖ Purchase Invoice/Memo Create</li>
              <li className="mt-2">❖ Sales/Purchase Return</li>
              <li className="mt-2">❖ Item Transfer</li>
              <li className="mt-2">❖ Representative Management</li>
              <li className="mt-2">❖ BARCODE Enabled System</li>
              <li className="mt-2">❖ Real time accounting </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6  col-12 col-sm-6 col-md-6 ">
          <div className="omega-background">
          <p className="count-text text-start px-3  text-danger">Reports</p>
              <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
              <li className="mt-2">❖ Category, Brand, Item wise Stock Report.</li>
              <li className="mt-2">❖ Category, Brand, Item wise Stock Report (with price).</li>
              <li className="mt-2">❖ Stock Movement Register.</li>
              <li className="mt-2">❖ Day Book Report</li>
              <li className="mt-2">❖ Sales Register</li>
              <li className="mt-2">❖ Party wise Sales Report</li>
              <li className="mt-2">❖ Purchase Register</li>
              <li className="mt-2">❖ Product wise gross profit.</li>
              <li className="mt-2">❖ Item Warning Report</li>
              <li className="mt-2">❖ Item Transfer Report</li>
              <li className="mt-2">❖ Representative Sales Report</li>
           
            </ul>
          </div>
        </div>

      </div>
      </div>
      {/* 2nd part */}

      <div className="pt-5 container-feature text-center mx-auto pt-5  col-lg-12  col-12  rounded-5" >
        <p className="display-6">Key Features of Accounting:</p>
      
      <div className=" row">
        <div className="col-lg-6  col-12 col-sm-6 col-md-6  ">
          <div className="omega-background">
          <p className="count-text text-start px-3  text-danger">Activities</p>
              <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
              <li className="mt-2">❖ Chart of Accounts</li>
              <li className="mt-2">❖ Opening Balance</li>
              <li className="mt-2">❖ Vouchers (Receipt, Payment, Journal, Contra, Balance Transfer)</li>
              <li className="mt-2">❖ Party Management</li>
              <li className="mt-2">❖ Employee/Staff Management</li>
              <li className="mt-2">❖ Configuration</li>
            
            </ul>
          </div>
        </div>
        <div className="col-lg-6  col-12 col-sm-6 col-md-6 ">
          <div className="omega-background">
          <p className="count-text text-start px-3  text-danger">Reports</p>
              <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
              <li className="mt-2">❖ Chart of Accounts</li>
              <li className="mt-2">❖ Ledger Report</li>
              <li className="mt-2">❖ Daily Transaction</li>
              <li className="mt-2">❖ Cash Book</li>
              <li className="mt-2">❖ Bank Book</li>
              <li className="mt-2">❖ Cash & Bank Book</li>
              <li className="mt-2">❖ Trial Balance Report</li>
              <li className="mt-2">❖ Receipt Payment Statement</li>
              <li className="mt-2">❖ Income Statement or Profit-Loss Statement</li>
              <li className="mt-2">❖ Balance Sheet Report</li>
              <li className="mt-2">❖ Party Ledger Report</li>
              <li className="mt-2">❖ Accounts payable receivable </li>
            </ul>
          </div>
        </div>

      </div>
      </div>


      </div>
  </div>



  );
};

export default OmegaDMS;
