import React from "react";
import "./Contact.css";
import logo from "../../../assets/logo/contact.png";

const Contact = () => {
  return (
    <div className="container-fluid  contact-form  ">
      <div className="container-contact contact-image">
        <img className="" src={logo} alt="" />
      </div>
      <form>
        <p className="display-6 text-danger">Drop Us a Message</p>
        <div className="row">
          <div className="col-md-6  col-6">
            <div className="form-group">
              <input
                name="txtName"
                className="form-control"
                placeholder="Your Name *"
              />
            </div>
            <div className="form-group">
              <input
                name="txtEmail"
                className="form-control"
                placeholder="Your Email *"
              />
            </div>
            <div className="form-group">
              <input
                name="txtPhone"
                className="form-control"
                placeholder="Your Phone Number *"
              />
            </div>
          </div>

          <div className="col-md-6  col-6">
            <div className="form-group">
              <textarea
                name="txtMsg"
                className="form-control area-control"
                placeholder="Your Message *"
              ></textarea>
            </div>
          </div>

          <div className="form-group">
            <input
              type="submit"
              name="btnSubmit"
              className="btn btnContact"
              value="Send Message"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Contact;
