import React from 'react';

import Header from '../../Home/Header/Header';
import Subscribe from '../../Pages/Subscribe/subscribe';
import Service from '../../Pages/Service/Service';
import Makeslider from '../../Pages/Clientslider/Makeslider';
import Values from '../Values/Values';
import Counter from '../../Pages/Counter/Counter';



/** 
	* OmegaSoftBD home.
	* @pupose		Home page information	
	* @filesource	src/Components/Home/Home/Home.js	
	* @package		omegasoft 
	* @subpackage	omegasoft.Home.
	* @version      $Revision: 1
	* @author       $Author: Mohtasim Shariar  $
	* @lastmodified $Date: 2022-10-18 $	
*/ 


const Home = () => {
  return (
    <div className="App" >
       <Header></Header>
       <Values></Values>
       <Counter></Counter>
       <Service></Service>
       <Makeslider></Makeslider>
       <Subscribe></Subscribe>
    </div>
  );
};

export default Home;
