import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <section className=" container-fluid header-content pt-5">
      <div className="container-header  row ">
        <div className="col-lg-6  text-area">
          <div>
            <strong className="display-6">
              Welcome To{" "}
              <span style={{ color: "#dd2c2c" }}>
                <br /> OmegaSoft <br /> Best Software Solution
                <br /> In Your City{" "}
              </span>{" "}
            </strong>
            <p>Let's start here</p>

            <Link to="/contact" className="btn btn-large btn-danger ">
              <i className="icon-home icon-white"></i> REQUEST A QUOTE
            </Link>
          </div>
        </div>
        <div className="col-lg-6 button-area ">
          <div class="p-3 mb-2 bg-danger bg-gradient text-white rounded-5">
            {" "}
            <Link className="text-decoration-none text-light" to="#">
              <i className="fa fa-2x  fa-thin fa-cloud text-white px-2"></i>
              <span className="header-text">CLOUD ERP</span>
            </Link>
          </div>

          <div class="p-3 mb-2 bg-danger bg-gradient text-white rounded-5">
            {" "}
            <Link className="text-decoration-none text-light  " to="#">
              <i className="fa fa-2x fa-thin fa-code text-white px-2"></i>
              <span className="header-text">DEVELOPMENT</span>
            </Link>
          </div>

          <div class="p-3 mb-2 bg-danger bg-gradient text-white rounded-5">
            {" "}
            <Link className="text-decoration-none text-light" to="#">
              <i className="fa fa-2x fa-brands fa-slack text-white px-2"></i>{" "}
              <span className="header-text">MODERN DESIGN</span>
            </Link>
          </div>

          <div class="p-3 mb-2 bg-danger bg-gradient text-white rounded-5">
            {" "}
            <Link className="text-decoration-none text-light" to="#">
              <i className="fa fa-2x fa-android text-white px-2"></i>{" "}
              <span className="header-text">MOBILE APPS</span>
            </Link>
          </div>

          <div class="p-3 mb-2 bg-danger bg-gradient text-white rounded-5">
            {" "}
            <Link className="text-decoration-none text-light  " to="">
              <i className="fa fa-2x   fa-thin fa-desktop text-white px-2"></i>{" "}
              <span className="header-text">WEBSITE</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
