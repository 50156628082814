import React from "react";
import "./OmegaHMS.css";
import omegaproduct1 from "../../../../assets/images/omegasoftwork2.jpg";

const OmegaHMS = () => {
  return (
    <div className="container-fluid pt-5 pb-5">
      <div className="container-omega">
        
      
      <div className="text-center mx-auto  ">
        <h2 className=" d-inline-block text-danger  ">Omega Hospital Management System</h2>
      </div>

      <div className="products-section">
        <div className="row">
          <div className="col-md-6 product-img">
          <img className="rounded-circle img-fluid" src={omegaproduct1} alt="" />
          </div>
          <div className="col-md-6">
            <h2 className="display-6">OmegaHMS System</h2>
            <p className="subheading">Description Of Our System</p>
            <p className="text_style text-muted">
            OmegaHMS is a Hospital Management Software designed for small and mid-size hospitals and clinics, individual doctors, physicians. It is a rich web based application software which has all the features that you would look for in a Hospital Management software that helps you take effective decisions for patient care, patient management, hospital management, administration and streamline financial accounting aspects of your hospital or clinic. We have designed this easy management, as much as paperless, user-friendly and time saving.
            </p>
          </div>
        </div>
      </div>


 
{/* Details */}
<div className="pb-5 container-feature text-center mx-auto pt-5  col-lg-12  col-12 rounded-5">
          <p className="display-6">Key Features Of Inventory:</p>
        
        <div className=" row">
          <div className="col-lg-6  col-12 col-sm-6 col-md-6  ">
            <div className="omega-background">
            <p className="count-text text-start px-3  text-danger">Activities</p>
              <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
                <li className="mt-2">❖ Patients Information </li>
                <li className="mt-2">❖ Doctors Information with Commission</li>
                <li className="mt-2">❖ Referrer Information with Commission</li>
                <li className="mt-2">❖ Test Information</li>
                <li className="mt-2">❖ Cabin/Ward Information</li>
                <li className="mt-2">❖ Admission Package Information</li>
                <li className="mt-2">❖ Patient Admit Info</li>
                <li className="mt-2">❖ Patient Discharge </li>
                <li className="mt-2">❖ Invoice Management</li>
                <li className="mt-2">❖ Due Collection</li>
                <li className="mt-2">❖ Real time accounting </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6  col-12 col-sm-6 col-md-6 ">
            <div className="omega-background">
            <p className="count-text text-start px-3  text-danger">Reports</p>
              <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
                <li className="mt-2">❖ Daily Invoice Summary Report</li>
                <li className="mt-2">❖ Patient wise Ledger Report</li>
                <li className="mt-2">❖ Outdoor Patient Report</li>
                <li className="mt-2">❖ Referrer Commission Report</li>
          
              </ul>
            </div>
          </div>
        </div>
        </div>
        {/* 2nd part */}

        <div className="pt-5 container-feature text-center mx-auto pt-5  col-lg-12  col-12  rounded-5" >
          <p className="display-6">Key Features of Accounting:</p>
        
        <div className=" row">
          <div className="col-lg-6  col-12 col-sm-6 col-md-6  ">
            <div className="omega-background">
            <p className="count-text text-start px-3  text-danger">Activities</p>
              <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
                <li className="mt-2">❖ Chart of Accounts </li>
                <li className="mt-2">❖ Opening Balance</li>
                <li className="mt-2">❖ Vouchers (Receipt, Payment, Journal, Contra)</li>
                <li className="mt-2">❖ Party Information</li>
                <li className="mt-2">❖ Employee Management</li>
                <li className="mt-2">❖ Employee Salary Sheet</li>
                <li className="mt-2">❖ Configuration</li>
              
              </ul>
            </div>
          </div>
          <div className="col-lg-6  col-12 col-sm-6 col-md-6 ">
            <div className="omega-background">
            <p className="count-text text-start px-3  text-danger">Reports</p>
              <ul className="text-start  px-5 justify-content-start roll-list list-unstyled   text-muted">
                <li className="mt-2">❖ Chart of Accounts</li>
                <li className="mt-2">❖ Ledger Report</li>
                <li className="mt-2">❖ Daily Transaction</li>
                <li className="mt-2">❖ Cash Book</li>
                <li className="mt-2">❖ Bank Book</li>
                <li className="mt-2">❖ Cash & Bank Book</li>
                <li className="mt-2">❖ Trial Balance Report</li>
                <li className="mt-2">❖ Receipt Payment Statement</li>
                <li className="mt-2">❖ Income Statement or Profit-Loss Statement</li>
                <li className="mt-2">❖ Balance Sheet Report</li>
                <li className="mt-2">❖ Accounts Payable Receivable Report</li>
                <li className="mt-2">❖ Party Ledger Report</li>
                <li className="mt-2">❖ Employee Salary Sheet Report</li>
              </ul>
            </div>
          </div>

        </div>
        </div>


        </div>
    </div>


  );
};

export default OmegaHMS;
