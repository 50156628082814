import React from "react";
import "./Service.css";

import Stock_Management_Systems from "../../../assets/Icons_OmegaERP/Stock Management Systems.png";
import RMA_Service_Center from "../../../assets/Icons_OmegaERP/RMA_Service Center.png";
import POS_Barcode_Buy_Sale from "../../../assets/Icons_OmegaERP/POS_Barcode Buy-Sale.png";
import Restaurant_Management from "../../../assets/Icons_OmegaERP/Restaurant Management.png";
import Rice_Feed_Mill from "../../../assets/Icons_OmegaERP/Rice & Feed Mill.png";
import Timber_Mill from "../../../assets/Icons_OmegaERP/Timber Mill.png";
import Pharmaceutical_Madicine from "../../../assets/Icons_OmegaERP/Pharmaceutical & Madicine.png";
import Real_Estate_Developer from "../../../assets/Icons_OmegaERP/Real Estate_Developer.png";
import Office_Automation from "../../../assets/Icons_OmegaERP/Office Automation.png";
import Motor_Parts_Shop from "../../../assets/Icons_OmegaERP/Motor_Parts Shop.png";
import HR_Payroll_Systems from "../../../assets/Icons_OmegaERP/HR & Payroll Systems.png";
import Hospital_Management from "../../../assets/Icons_OmegaERP/Hospital Management.png";
import GarmentsSpining_Mill from "../../../assets/Icons_OmegaERP/GarmentsSpining Mill.png";
import Fisheries from "../../../assets/Icons_OmegaERP/Fisheries.png";
import Accounting_Systems from "../../../assets/Icons_OmegaERP/Accounting Systems.png";
import Brick_Field from "../../../assets/Icons_OmegaERP/Brick Field.png";
import Cloth_Store from "../../../assets/Icons_OmegaERP/Cloth Store.png";
import Electronics_Shop from "../../../assets/Icons_OmegaERP/Electronics Shop.png";
import education_Management from "../../../assets/Icons_OmegaERP/education Management.png";
import Factory_Management from "../../../assets/Icons_OmegaERP/Factory Management.png";
import Filling_Station from "../../../assets/Icons_OmegaERP/Filling Station.png";
import Computer_Mobile_Shop from "../../../assets/Icons_OmegaERP/Computer & Mobile Shop.png";
import CRM from "../../../assets/Icons_OmegaERP/CRM.png";








const Service = () => {
  return (
    <div className="container-fluid pt-5 pb-5">
      <div className="text-center mx-auto">
        <h2 className="d-inline-block text-danger ">Industries We Serve</h2>
        <p className="display-6">
          We Provide Innovation For Following Industries
        </p>
      </div>

      <div className="container-service social-box">
        <div className="row">
          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img
                className="service_icon"
                src={Stock_Management_Systems}
                alt=""
              />
              <div className="box-title">
                <p>Inventory Management </p>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={RMA_Service_Center} alt="" />
              <div className="box-title">
                <p>RMA Service Center</p>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={POS_Barcode_Buy_Sale} alt="" />
              <div className="box-title">
                <p>POS Barcode Buy-Sale</p>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img
                className="service_icon"
                src={Restaurant_Management}
                alt=""
              />
              <div className="box-title">
                <p>Restaurant Management</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={Rice_Feed_Mill} alt="" />
              <div className="box-title">
                <p>Rice & Feed Mill</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img
                className="service_icon"
                src={Pharmaceutical_Madicine}
                alt=""
              />
              <div className="box-title">
                <p>Pharmaceutical & Madicine</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={Timber_Mill} alt="" />
              <div className="box-title">
                <p>Timber Mill</p>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img
                className="service_icon"
                src={Real_Estate_Developer}
                alt=""
              />
              <div className="box-title">
                <p>Real Estate Developer</p>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={Office_Automation} alt="" />
              <div className="box-title">
                <p>Office Automation</p>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={Motor_Parts_Shop} alt="" />
              <div className="box-title">
                <p>Motor Parts Shop</p>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={HR_Payroll_Systems} alt="" />
              <div className="box-title">
                <p>HR & Payroll Systems</p>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={Hospital_Management} alt="" />
              <div className="box-title">
                <p>Hospital Management</p>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={GarmentsSpining_Mill} alt="" />
              <div className="box-title">
                <p>GarmentsSpining Mill</p>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={Fisheries} alt="" />
              <div className="box-title">
                <p>Fisheries</p>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={Office_Automation} alt="" />
              <div className="box-title">
                <p>Office Automation</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={Accounting_Systems} alt="" />
              <div className="box-title">
                <p>Accounting Systems</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={Brick_Field} alt="" />
              <div className="box-title">
                <p>Brick Field</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={Cloth_Store} alt="" />
              <div className="box-title">
                <p>Cloth Store</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={Electronics_Shop} alt="" />
              <div className="box-title">
                <p>Electronics Shop</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={education_Management} alt="" />
              <div className="box-title">
                <p>education Management</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={Factory_Management} alt="" />
              <div className="box-title">
                <p>Factory Management</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={Filling_Station} alt="" />
              <div className="box-title">
                <p>Filling Station</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={Computer_Mobile_Shop} alt="" />
              <div className="box-title">
                <p>Computer Mobile Shop</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-3 col-md-3 col-6   text-center">
            <div className="box">
              <img className="service_icon" src={CRM} alt="" />
              <div className="box-title">
                <p>CRM System</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Service;
