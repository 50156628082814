import React from "react";
import "./Navigation.css";
import { Link, NavLink } from "react-router-dom";
import { Nav, Navbar, NavDropdown, Container } from "react-bootstrap";

import Logo from "../../../assets/logo/Logo-OmegaSoft.png";

const Navigation = () => {
  return (
    <Navbar
      className="container-fluid navbar-light"
      collapseOnSelect
      sticky="top"
      expand="lg"
      bg="light"
      variant="light"
    >
      <Container className="">
        <NavLink to="/home" className="">
          <Navbar.Brand>
            <img className="logo" src={Logo} alt="" />
          </Navbar.Brand>
        </NavLink>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>

          <Nav className=" ">
            <NavLink
              className="link "
              to="/home"
              style={({ isActive }) => ({
                color: isActive ? "red" : "#000",
                borderBottom: isActive ? "5px solid red" : "none",
              })}
            >
              Home
            </NavLink>

            <NavLink
              className="link "
              to="/about"
              style={({ isActive }) => ({
                color: isActive ? "red" : "#000",
                borderBottom: isActive ? "5px solid red" : "none",
              })}
            >
              About
            </NavLink>

            <NavLink
              className="link "
              to="/service"
              style={({ isActive }) => ({
                color: isActive ? "red" : "#000",
                borderBottom: isActive ? "5px solid red" : "none",
              })}
            >
              Services
            </NavLink>

            <NavLink
              className="link product-link"
              to="/product"
              style={({ isActive }) => ({
                color: isActive ? "red" : "#000",
                borderBottom: isActive ? "5px solid red" : "none",
              })}
            >
              <NavDropdown
                title="Products"
                id="collasible-nav-dropdown"
                className="dropdown-item "
              >
                <Link to="/omegaERP" className="dropdown-item">
                  <NavDropdown.Item href="#omegaERP">OmegaERP</NavDropdown.Item>
                </Link>
                <Link to="/omegaDMS" className="dropdown-item">
                  <NavDropdown.Item href="#omegaDMS">
                    {" "}
                    OmegaDMS
                  </NavDropdown.Item>
                </Link>
                <Link to="/omegaHMS" className="dropdown-item">
                  <NavDropdown.Item href="#omegaHMS">OmegaHMS</NavDropdown.Item>
                </Link>
                <Link to="/DoctorPad" className="dropdown-item">
                  <NavDropdown.Item href="#DoctorPad">
                    Doctors Pad
                  </NavDropdown.Item>
                </Link>
              </NavDropdown>
            </NavLink>

          {/*  
            <NavLink
              className="link"
              to="/pricing"
              style={({ isActive }) => ({
                color: isActive ? "red" : "#000",
                borderBottom: isActive ? "5px solid red" : "none",
              })}
            >
              Pricing
            </NavLink>
           */} 
           
            <NavLink
              className="link"
              to="/contact"
              style={({ isActive }) => ({
                color: isActive ? "red" : "#000",
                borderBottom: isActive ? "5px solid red" : "none",
              })}
            >   
              Contact
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
