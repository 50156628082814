import React from "react";
import "./Makeslider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import client1 from "../../../assets/clients/inside.png";
import client2 from "../../../assets/clients/addl.png";
import client3 from "../../../assets/clients/aggo.jpg";
import client4 from "../../../assets/clients/ahmedtraders.png";
import client5 from "../../../assets/clients/homemac.png";
import client6 from "../../../assets/clients/jamiya.jpg";
import client7 from "../../../assets/clients/jonyint.png";
import client8 from "../../../assets/clients/jtcorp.png";

import client9 from "../../../assets/clients/mkmat.png";
import client10 from "../../../assets/clients/naymaent.png";
import client11 from "../../../assets/clients/osman.jpg";
import client12 from "../../../assets/clients/piscgroup.png";
import client13 from "../../../assets/clients/riadbattery.png";
import client14 from "../../../assets/clients/rn_pharma.png";
import client15 from "../../../assets/clients/sarinda.png";
import client16 from "../../../assets/clients/sbal.png";

import client17 from "../../../assets/clients/shch.png";
import client18 from "../../../assets/clients/ss.png";
import client19 from "../../../assets/clients/t.jpg";

import client20 from "../../../assets/clients/ahmedtraders.png";
import client21 from "../../../assets/clients/homemac.png";
import client22 from "../../../assets/clients/jamiya.jpg";
import client23 from "../../../assets/clients/jonyint.png";
import client24 from "../../../assets/clients/jtcorp.png";

const Makeslider = () => {
  const settings = {
    className: "center",
    //centerMode: true,
    infinite: true,
    slidesToShow: 4,
    centerPadding: "0px",
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    cssEase: "linear",
    arrows: false,
    rows: 2,
    slidesPerRow: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
          rows: 1,
          slidesPerRow: 1,
        },
      },
    ],
  };
  return (
    <div className="container1 pt-5 pb-5">
      <div className="container-client">
        <div className="text-center mx-auto">
          <h2 className="d-inline-block text-danger  ">Our Happy Clients</h2>
          <p className="display-6">Big Brand Who Trust Us</p>
        </div>

        <Slider {...settings} className="clients1">
          <div className="">
            <div className="client-background">
              <img className="  client_logo" src={client1} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client2} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="   client_logo " src={client3} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client4} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client5} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="   client_logo " src={client6} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="   client_logo " src={client7} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="   client_logo " src={client8} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client9} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client10} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client11} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client12} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client13} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client14} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client15} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client16} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client17} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client18} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client19} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="   client_logo " src={client20} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="   client_logo " src={client21} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client22} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="    client_logo" src={client23} alt="" />
            </div>
          </div>
          <div>
            <div className="client-background">
              <img className="   client_logo " src={client24} alt="" />
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Makeslider;
